<template>
    <div class="operator-report">
        <div class="operator-report__header">
            <div class="operator-report__name">{{ $t("components.common.yourStatistics") }}</div>
            <ul class="operator-report__info-wrap">
                <li v-for="item in operatorReportList" :key="item.title" class="operator-report__info operator-info">
                    <img v-if="item.image" :src="item.image" alt="" class="operator-info__icon">
                    <AppIcon v-else class="operator-info__icon" :icon="item.icon"/>
                    <p class="operator-info__name">{{ $t(item.title) }}</p>
                    <p class="operator-info__value">{{ item.value }}</p>
                </li>
            </ul>
        </div>
        <LoadingContent :loading="loading">
            <div v-if="Object.keys(oDiagrams).length" class="operator-report__body">
                <section v-for="(item, key) in diagramsTitles" class="operator-report__item report-item">
                    <h3 class="report-item__name">{{ item }}</h3>
                    <p class="report-item__title">{{ (selectedDiagrams[key] ? diagramsRows[selectedDiagrams[key]] : diagramsRows.avg) }}</p>
                    <p class="report-item__value">{{ (selectedDiagrams[key] ? oDiagrams[key][selectedDiagrams[key]] : oDiagrams[key].avg) | timeFromMS(true) }}</p>
                    <ul class="report-item__list">
                        <li v-for="(row, rowKey) in diagramsRows" :key="rowKey" class="report-item__row" :class="{'report-item__row--active': (selectedDiagrams[key] || 'avg') === rowKey}"
                            @click="selectRow(rowKey, key)">
                            <p class="report-item__list-title">{{ row }}</p>
                            <p class="report-item__list-value">{{ oDiagrams[key][rowKey] | timeFromMS(true) }}</p>
                        </li>
                    </ul>
                </section>
            </div>
            <EmptyContent v-else title="components.common.noData"/>
        </LoadingContent>
    </div>
</template>
<script>
import LoadingContent from "../../LoadingContent";
import EmptyContent from "../../EmptyContent";
import AppIcon from "../../AppIcon";
import {get} from "../../../../helpers/api";
import {getPluralRU} from "../../../../helpers/other";
import filterDate from "../../../../mixins/FilterDate";

const datas = Object.freeze({
    diagramsTitles: {
        talkTimeChart: 'Время разговора',
        holdTimeChart: 'Время удержания',
        readyTimeChart: 'Готов',
        notReadyTimeChart: 'Не готов',
        workTimeChart: 'Поствызовная работа'
    },
    diagramsRows: {
        avg: 'Среднее',
        max: 'Максимальное',
        total: 'Всего'
    },
});

export default {
    name: 'OtchetOperatorReport',
    components: {AppIcon, EmptyContent, LoadingContent},
    mixins: [filterDate],
    props: {
        dataItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            diagramsTitles: datas.diagramsTitles,
            diagramsRows: datas.diagramsRows,
            selectedDiagrams: {},
            operatorRating: null,
            customerCount: null,
            customersTotal: null,
            CSIDataVisible: false,
        };
    },
    computed: {
        oDiagrams() {
            return this.dataItem.data
        },
        loading() {
            return this.dataItem.loading;
        },
        todayRate() {
            let rating = this.operatorRating || 0;
            const ratings = [this.$t('profile.nothing'),this.$t('profile.worst'),this.$t('profile.bad'),this.$t('profile.soSo'),this.$t('profile.good'),this.$t('profile.great')];
            return `${rating.toFixed(1)} (${ratings[Math.round(rating)]})`
        },
        customerRated() {
            const count = this.customerCount || 0;
            return `${count} ${getPluralRU(count, this.$t('profile.client'), this.$t('profile.client2'), this.$t('profile.client3'))}`;
        },
        operatorReportList() {
            return [
                {
                    title: 'profile.proposed',
                    value: this.$t('profile.callCount', { count: this.oDiagrams.nPresentedContacts || 0 }),
                    icon: 'time',
                },
                {
                    title: 'profile.processed',
                    value: this.$t('profile.callCount', { count: this.oDiagrams.nHandledContacts || 0 }),
                    icon: 'circle-check',
                },
                ...(this.CSIDataVisible ? [
                        {
                            title: 'profile.scoreToday',
                            value: this.todayRate,
                            icon: 'emoji-happy'
                        },
                        {
                            title: 'profile.youHaveBeenRated',
                            value: this.$t('profile.actualRate', { rate: this.customerRated, totalRate: this.customersTotal }),
                            icon: 'users'
                        },
                    ] : []
                )
            ]
        }
    },
    mounted() {
        get('/ratings/access')
            .then(r => {
                this.CSIDataVisible = r.data.enabled
                if(this.CSIDataVisible) {
                    get('/ratings/average-rating')
                        .then(r => {
                            this.operatorRating = r.data.average_rating;
                            this.customerCount = r.data.grades_number;
                            this.customersTotal = r.data.customer_count;
                        })
                }
            })
    },
    methods: {
        selectRow(rowKey, diagramKey) {
            this.$set(this.selectedDiagrams, diagramKey, rowKey);
        },
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';

.operator-info {
    font-family: $font-secondary;
    position: relative;
    padding-left: 40px;
    color: var(--color-dark-light);
    &__icon {
        color: #2552C6;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -12px;
        width: 24px;
        height: 24px;
        display: block;
    }
    &__name {
        margin: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #2552C6;
    }
    &__value {
        margin: 0;
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #212121;
    }
}
.report-item {
    $self: &;
    font-family: $font-primary;
    background: var(--color-white);
    border: 1px solid var(--color-border);
    border-radius: 8px;
    padding: 31px;
    display: flex;
    flex-direction: column;
    &__name {
        min-height: 48px;
        margin: 0 0 auto;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: var(--color-primary);
    }
    &__title {
        margin: 10px 0 2px;
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-gray-lighten);
    }
    &__value {
        margin: 0 0 22px;
        font-weight: 400;
        font-size: 40px;
        line-height: 49px;
        font-family: $font-secondary;
        color: var(--color-gray);
    }
    &__list {
        border-top: 1px solid var(--color-border);
        margin: 0;
        padding: 23px 0 0 0;
        list-style-type: none;
    }
    &__row {
        cursor: pointer;
        user-select: none;
        margin: 0 0 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--active {
            #{$self}__list-title {
                color: var(--color-gray-lighten);
            }
        }
    }
    &__list-title {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        font-family: $font-secondary;
        letter-spacing: 0.005em;
        color: var(--color-primary-light);
    }
    &__list-value {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-black);
    }
}
.operator-report {
    &__header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 72px;
        padding: 16px 42px;
        background: #EDF2FF;
    }
    &__name {
        margin: 0 192px 0 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }
    &__info {
        margin-left: 56px;
        margin-top: 24px;
    }
    &__info-wrap {
        padding: 0;
        margin: -24px 0 0 -48px;
        list-style-type: none;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__body {
        padding: 0 42px 0 10px;
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        margin-left: 32px;
        margin-top: 32px;
        width: calc(20% - 32px);
        min-width: 250px;
    }
}

</style>
