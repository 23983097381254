<template>
    <div class="pagination-info">
        {{ min }}-{{ max }} {{ $t("components.common.from") }} {{ total }}
    </div>
</template>
<script>
export default {
    name: 'PaginationInfo',
    props: {
        min: [String, Number],
        max: [String, Number],
        total: [String, Number]
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.pagination-info {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-gray);
    font-family: $font-primary;
}
</style>
