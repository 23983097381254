<template>
  <div class="otchet-page">
    <div class="otchet-page__filter application-input-theme-login">
      <AppSelect
        v-if="!!filters.select"
        :value="model.select"
        class="otchet-page__filter-item otchet-page__filter-item--select"
        size="large"
        :placeholder="$t('components.common.operatorGroup')"
        default-value=""
        :items="filters.select.items"
        item-name="name"
        @input="onInput('select', $event)"
      />
      <div
        v-if="filters.time"
        class="otchet-page__filter-item otchet-page__filter-item--time otchet-time"
      >
        <AppTimePicker
          :value="model.from"
          class="otchet-time__field"
          allow-clear
          :placeholder="$t('components.common.timeWith')"
          size="large"
          @input="onInput('from', $event)"
        />
        <div class="otchet-time__separator">
          —
        </div>
        <AppTimePicker
          :value="model.to"
          class="otchet-time__field"
          allow-clear
          :placeholder="$t('components.common.timeBy')"
          size="large"
          @input="onInput('to', $event)"
        />
      </div>
      <AppInput
        v-if="filters.phone"
        v-model="model.phone"
        v-mask="phoneMask"
        placeholder="XX XXX XX XX"
        class="otchet-page__filter-item otchet-page__filter-item--phone"
        size="large"
      />
      <AppButton
        v-if="dataItem.filterReset"
        square
        theme="white-outline-narrow"
        @click="reset"
      >
        <AppIcon icon="delete" />
      </AppButton>
    </div>
    <LoadingContent :loading="loading">
      <AppTable
        v-if="list.length"
        :head="tableHead"
        :data="listPaginated"
        theme="card"
      >
        <!-- Эта сложная конструкция позволяет обращаться к слотам таблицы снаружи компонента. Удобно, если нужно добавить ячейку с компонентами -->
        <template
          v-for="({key}) in tableHead[0]"
          v-slot:[getCommonStatsCellKey(key)]="{item, rowIndex}"
        >
          <slot
            :name="getCommonStatsCellKey(key)"
            :item="item"
            :row-index="rowIndex"
          >
            {{ customKeys && key in customKeys ? customKeys[key](item, rowIndex) : item[key] }}
          </slot>
        </template>
        <template #cell.id="{rowIndex}">
          {{ rowIndex + paginationMin }}
        </template>
      </AppTable>
      <EmptyContent
        v-else
        title="components.common.noData"
      />
      <div
        v-if="list.length > perPage && dataItem.pagination"
        class="otchet-page__pagination"
      >
        <PaginationInfo
          :min="paginationMin"
          :max="paginationMax"
          :total="list.length"
        />
        <b-pagination
          class="m-0"
          next-class="prev"
          prev-class="next"
          prev-text=""
          next-text=""
          v-model="model.page"
          :total-rows="list.length"
          :per-page="perPage"
        />
      </div>
    </LoadingContent>
  </div>
</template>
<script>
import AppSelect from "../../AppSelect";
import LoadingContent from "../../LoadingContent";
import AppTable from "../../AppTable";
import EmptyContent from "../../EmptyContent";
import PaginationInfo from "../../PaginationInfo";
import AppTimePicker from "../../AppTimePicker";
import AppInput from "../../AppInput";
import AppIcon from "../../AppIcon";
import AppButton from "../../AppButton";
export default {
    name: "OtchetTabPage",
    components: {
        AppButton,
        AppIcon, AppInput, AppTimePicker, PaginationInfo, EmptyContent, AppTable, LoadingContent, AppSelect},
    props: {
        dataItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            model: {
                page: 1,
                select: "",
                from: "",
                to: "",
                phone: "",
            },
            perPage: 10,
            phoneMask: "#########",
            innerLoading: false
        };
    },
    computed: {
        filters() {
            return this.dataItem.filters;
        },

        list() {
            let filtered = this.dataItem.array;

            const safeModel = {
                ...this.model,
            };
            Object.entries(this.filters).forEach(([_, filterObj]) => {
                filtered = filtered.filter(item => filterObj.filter(item, safeModel));
            });

            return filtered;
        },
        listPaginated() {
            if(!this.dataItem.pagination) return this.list;
            return this.list.slice(this.paginationMin - 1, this.paginationMax);
        },
        loading() {
            return this.innerLoading || this.dataItem.loading;
        },
        tableHead() {
            return this.dataItem.head;
        },
        paginationMin() {
            return (this.model.page - 1) * this.perPage + 1;
        },
        paginationMax() {
            return Math.min(this.model.page * this.perPage, this.list.length);
        },
        customKeys() {
            return this.dataItem.customKeys;
        }
    },
    methods: {
        reset() {
            this.model = {
                page: 1,
                select: "",
                from: "",
                to: "",
                phone: "",
            };
        },
        onInput(key, value) {
            if(this.loading) return;

            if(key === "select") {
                this.innerLoading = true;
                setTimeout(() => {
                    this.$set(this.model, key, value);
                    this.$set(this.model, "page", 1);
                    this.innerLoading = false;
                }, 250);
            } else {
                this.$set(this.model, key, value);
                this.$set(this.model, "page", 1);
            }

            if(key === "from" || key === "to") {
                this.filters.time?.event?.(this.model.from + "-" + this.model.to);
            } else {
                this.filters[key]?.event?.(value);
            }
        },
        getCommonStatsCellKey(key) {
            return "cell." + key;
        },
    },
};
</script>
<style lang="scss">
@import '@/assets/scss/variables';

.otchet-page {
    position: relative;
    &__filter {
        position: absolute;
        top: -148px;
        left: 590px;
        display: flex;
        z-index: 3;
    }
    &__filter-item {
        margin-right: 32px;
        &--time {
            width: 414px;
        }
        &--select {
            width: 304px;
        }
        &--phone {
            width: 224px;
            margin-right: 16px;
        }
    }
    &__pagination {
        padding: 22px 42px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}
.otchet-time {
    display: flex;
    align-items: center;
    &__field {
        width: calc(50% - 15px);
    }
    &__separator {
        font-family: $font-primary;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
        width: 30px;
        text-align: center;
    }
}
</style>
