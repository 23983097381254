<template>
  <span>
    {{ time }}
  </span>
</template>

<script>
import filterDate from "../mixins/FilterDate";

export default {
  mixins: [filterDate],

  props: {
    fromTime: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      time: "",
      intervalId: 0,
    };
  },

  methods: {
    timerDifference() {
      const now = new Date();
      this.time = this.$options.filters.timeFromMS(
        Math.abs(now.getTime() - this.fromTime),
        true
      );
    },
  },

  mounted() {
    this.timerDifference();
    this.intervalId = setInterval(() => {
      this.timerDifference();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>
