<template>
    <ModalWrapper transparent top-start ref="modal">
        <article class="report-modal">
            <div class="report-modal__header">
                <img class="report-modal__avatar" :src="operator.photo" alt="img"/>
                <div>
                    <p class="report-modal__heading">{{ operator.name }}</p>
                    <span class="report-modal__subheading">{{ $t("components.common.phoneNumber") }} {{ operator.phone }}</span>
                </div>
            </div>
            <div class="report-modal__body">
                <AppTabs :value="tabState" :items="reportTabs" theme="processing" keep-include="OtchetOperatorReport" @change="onTabChange">
                    <template v-for="stat in commonStatsList" v-slot:[getCommonStatsTabKey(stat.slot)]>
                        <component :key="stat.slot" :data-item="stat" :is="stat.component || 'OtchetTabPage'">
                            <!-- Этой странной конструкцией обращаемся к ячейке таблицы -->
                            <template #cell.phoneNumber="{item}">
                                <span class="spacer-button" @click="callPhone(item.phoneNumber)">
                                    {{ item.phoneNumber }}
                                    <img class="report-phone-icon" :src="require('../assets/img/otchet-phone.svg')">
                                </span>
                            </template>
                            <template #cell.state="{item}">
                                <div class="report-modal__tag default-tag default-tag--round" :class="getState(item.state)">{{item.state}}</div>
                            </template>
                            <template #cell.stateDuration="{item}">
                                <OtchetModalTimer v-if="!isCurrentUser(item.resourceId)" :from-time="item.timeChangedStateMillis" />
                                <span v-else>
                                    {{ agentCallStatusTimer.rawSeconds * 1000 | timeFromMS(true) }}
                                </span>
                            </template>
                        </component>
                    </template>
                </AppTabs>
            </div>
        </article>
    </ModalWrapper>
</template>

<script>
import {mapState} from 'vuex';
import {ResourceStats, StatisticsProxyClass} from "../helpers/StatisticsOperator";
import ModalWrapper from "./partials/ModalWrapper";
import filterDate from "../mixins/FilterDate";
import AppTabs from "./partials/AppTabs";
import oCiscoConfig from "../config/cisco";
import {EventBus} from "../helpers/eventBus";
import OtchetTabPage from "./partials/home/otchetModal/OtchetTabPage";
import OtchetOperatorReport from "./partials/home/otchetModal/OtcherOperatorReport";
import socket from "../services/StatistiscsSocket";

import OtchetModalTimer from "./otchet-modal-timer.vue";

import { agentCallStatusTimer } from "@/containers/timer-services"

const getCommonStatsKey = (name) => (key) => name + '.' + key;
const datas = Object.freeze({
    waitingHead: [[
        {
            name: '#',
            key: 'id',
            width: '90px'
        },
        {
            name: 'Канал обращения',
            key: 'channel'
        },
        {
            name: 'Ожидающих вызов',
            key: 'waiting'
        },
    ]],
    operatorHead: [[
        {
            name: '#',
            key: 'id',
            width: '90px'
        },
        {
            name: 'Имя оператора',
            key: 'resourceName'
        },
        {
            name: 'Время в системе',
            key: 'logonDuration'
        },
        {
            name: 'Текущее состояние',
            key: 'state'
        },
        {
            name: 'Длительность',
            key: 'stateDuration'
        },
    ]],
    callsHead: [[
        {
            name: 'Время',
            key: 'startTime'
        },
        {
            name: 'Продолжительность',
            key: 'duration'
        },
        {
            name: 'Тип',
            key: 'calType'
        },
        {
            name: 'Номер',
            key: 'phoneNumber'
        },
        {
            name: 'Очередь',
            key: 'csqName'
        },
        {
            name: 'Состояние',
            key: 'statusName'
        },

    ]],
    logsHead: [[
        {
            name: '#',
            key: 'id',
            width: '90px'
        },
        {
            name: 'Время',
            key: 'startTime'
        },
        {
            name: 'Продолжительность',
            key: 'duration'
        },
        {
            name: 'Состояние',
            key: 'agentState'
        },
        {
            name: 'Причина',
            key: 'reason'
        },
    ]],
    chatsHead: [[
        {
            name: '#',
            key: 'id',
            width: '90px'
        },
        {
            name: 'Название очереди',
            key: 'name'
        },
        {
            name: 'Клиентов в очереди',
            key: 'queue'
        },
        {
            name: 'Доступно агентов',
            key: 'available'
        },
    ]]
});
const timeFilter = (item, {from, to}) => {
    if(!from && !to) return true;

    const work = item.startTime.slice(11,13) + item.startTime.slice(14,16);
    const min = from.slice(0,2) + from.slice(3);
    const max = to.slice(0,2) + to.slice(3)

    const fromValid = from ? work >= min : true;
    const toValid = to ? work <= max : true;

    return fromValid && toValid;
}

export default {
    components: {
        OtchetOperatorReport,
        OtchetTabPage,
        AppTabs,
        ModalWrapper,
        OtchetModalTimer
    },
    mixins: [filterDate],
    data() {
        return {
            statLoading: false,
            tabState: '1',
            voiceStatistics: [],
            resourceStats: [],
            operatorCallLog: [],
            operatorStatusLog: [],
            oDiagrams: {},
            chatStatistics: [],
            oCiscoConfig,
            teamLoading: false,
            resourceFilterList: [],
            statisticsSubscribed: false,
            agentCallStatusTimer: agentCallStatusTimer.getData(),
        };
    },
    mounted() {
        if(this.isAdmin) {
            this.loadStatistic(2);
        }
        this.loadStatistic(this.tabState);
        this.$store.commit("changeStatus", 0);
    },
    methods: {
        isCurrentUser(username) {
            return this.userName === username
        },

        loadStatistic(iType) {
            const oDriver = new StatisticsProxyClass(iType, this.userName).getDrivers();
            if (null !== oDriver) {
                oDriver.getCallback().apply(this);
            }
        },
        getCommonStatsTabKey: getCommonStatsKey('body'),
        onTeamSelect(value) {
            if(this.teamLoading) return;

            this.teamLoading = true;

            if(!value) {
                this.resourceFilterList = [];
                this.teamLoading = false;
                return;
            }
            EventBus.$emit('finesse.getTeam', value, data => {
                this.teamLoading = false;
                this.resourceFilterList = data.map(r => r.loginId);
            }, () => {
                this.teamLoading = false;
            });
        },
        onTabChange(tab) {
            this.tabState = tab;

            if(this.isAdmin) {
                if(tab == 2) {
                    this.subscribeStatistics();
                } else {
                    this.loadStatistic(tab);
                    this.unsubscribeStatistics();
                }
            } else {
                this.loadStatistic(tab);
            }
        },
        subscribeStatistics() {
            if(this.statisticsSubscribed) return;
            this.statisticsSubscribed = true;
            socket.addGroupAndSubscribe({
                source: 'ResourceIAQStats',
                groups: [this.allOperatorsLoginString]
            }, this.onStatisticsMessage);
        },
        unsubscribeStatistics() {
            if(!this.statisticsSubscribed) return;
            this.statisticsSubscribed = false;
            socket.removeGroupAndUnsubscribe({
                source: 'ResourceIAQStats',
                groups: [this.allOperatorsLoginString]
            }, this.onStatisticsMessage);
        },
        onStatisticsMessage(message) {
            if(message.ResourceIAQStats) {
                const obj = this.operatorsDictionary[message.id];
                Object.entries(ResourceStats.getParsedMessage(message)).forEach(([key, value]) => {
                    this.$set(obj, key, value);
                });
            }
        },
        callPhone(phone) {
            EventBus.$emit('make-call', {phone});
            this.$refs.modal.close();
        },
        getState(name) {
            const states = {
                'Готов': 'default',
                'Не готов': 'danger',
                'Разговор': 'default outline',
                'Зарезервирован': 'warning outline',
                'Работает': 'accent',
                'В системе': 'primary',
            };
            const keywords = ['default-tag--theme-', 'default-tag--'];
            const keys = (states[name] || 'warning').split(' ');
            return keys.map((key, idx) => keywords[idx] + key).join(' ');
        }
    },
    computed: {
        ...mapState({
            status: state => state.status,
            operator: state => state.operator.operator,
            userName: state => state.auth.username,
            userTeams(state) {
                return [
                    {
                        id: '',
                        name: 'common.all'
                    },
                    ...state.userTeams
                ]
            }
        }),
        operatorsDictionary() {
            return this.resourceStats.reduce((acc, curr) => {
                acc[curr.resourceId] = curr;
                return acc;
            }, {});
        },
        allOperatorsLoginString() {
            return this.resourceStats.map(({resourceId}) => resourceId).join(',');
        },
        reportTabs() {
            return this.commonStatsList.map(stat => ({
                key: stat.slot,
                name: stat.name
            }));
        },
        commonStatsList() {
            return [
                {
                    name: 'profile.CSQReports',
                    slot: '1',
                    array: this.voiceStatistics,
                    head: datas.waitingHead,
                    loading: this.statLoading,
                    customKeys: {
                        channel: (item) => this.oCiscoConfig.VoiceGroupsInfo[item.VoiceIAQStats.esdName].name,
                        waiting: (item) => item.VoiceIAQStats.nWaitingContacts
                    },
                    filters: {}
                },
                {
                    name: 'profile.chatStatistics',
                    slot: '6',
                    array: this.chatStatistics,
                    head: datas.chatsHead,
                    loading: this.statLoading,
                    customKeys: {
                        name: (item) => this.oCiscoConfig.ChatGroupsInfo[item.id].name,
                        queue: (item) => item.ChatQueueStatistics.contactsQueued,
                        available: (item) => item.ChatQueueStatistics.agentsAvailable
                    },
                    filters: {}
                },
                {
                    name: 'profile.consolidatedReport',
                    slot: '2',
                    array: this.resourceStats,
                    head: datas.operatorHead,
                    loading: this.teamLoading || this.statLoading,
                    pagination: true,
                    customKeys: {
                        stateDuration: (item) => this.$options.filters.timeFromMS(Math.abs(item.timeChangedStateMillis - this.$store.state.serverTime), true),
                        state: (item) => `<div class="report-modal__tag default-tag default-tag--round ${getState(item.state)}">${item.state}</div>`
                    },
                    filters: {
                        select: {
                            filter: (item, model) => model.select ? this.resourceFilterList.includes(item.resourceId) : true,
                            items: this.userTeams,
                            event: this.onTeamSelect
                        }
                    },
                },
                {
                    name: 'profile.operatorStatistics',
                    slot: '3',
                    component: 'OtchetOperatorReport',
                    loading: this.statLoading,
                    data: this.oDiagrams
                },
                {
                    name: 'profile.recentCallLog',
                    slot: '4',
                    array: this.operatorCallLog,
                    head: datas.callsHead,
                    loading: this.statLoading,
                    filters: {
                        time: {
                            filter: timeFilter
                        },
                        phone: {
                            filter: (item, {phone}) => item.phoneNumber.includes(phone)
                        }
                    },
                    filterReset: true
                },
                {
                    name: 'profile.stateHistory',
                    slot: '5',
                    array: this.operatorStatusLog,
                    head: datas.logsHead,
                    loading: this.statLoading,
                    customKeys: {},
                    filters: {
                        time: {
                            filter: timeFilter,
                            event: (value) => console.log(value)
                        },
                    },
                    filterReset: true
                },
            ]
        },
        isAdmin() {
            return this.$store.state.auth.role === 'admin';
        }
    },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables';
.report-modal {
    background: var(--color-white);
    border-radius: 12px;
    width: 1524px;
    max-width: 100%;
    &__header {
        font-family: $font-secondary;
        padding: 36px 42px 32px;
        display: flex;
        align-items: flex-start;

    }
    &__avatar {
        width: 48px;
        height: 48px;
        border-radius: 100%;
        margin-right: 28px;
    }
    &__heading {
        margin: 0 23px 6px 0;
        width: 450px;
        font-weight: 500;
        font-size: 28px;
        line-height: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    &__subheading {
        margin: 0;
        font-weight: 500;
        display: block;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #ED1D24;
        font-family: $font-secondary;
    }
    &__body {
        padding-bottom: 36px;
    }
    &__tag {
        --tag-padding-x: 14px;
        --tag-padding-y: 3px;
        &:before {
            display: inline-block;
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 100%;
            margin-right: 6px;
            transform: translateY(-2px);
            background-color: currentColor;
        }
    }
}
.report-phone-icon {
    width: 32px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
    margin-top: -4px;
}
</style>
